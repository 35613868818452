import { Form, message, Modal } from 'antd';
import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { getBase64 } from '../../utils';
import { ImgDimensions } from '../../types';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Dropzone from 'react-dropzone';
import { LoadingOutlined, PictureOutlined } from '@ant-design/icons';


type Props = {
  onDismiss: () => void,
  docId: string,
};

function ImageUploadDialog({ onDismiss, docId }: Props) {
  const [file, setFile] = useState<File | null>(null);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formWarnings, setFormWarnings] = useState<{ [key: string]: string }>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [imageData, setImageData] = useState<null | string>(null);
  const [imgDimensions, setImgDimensions] = useState<ImgDimensions | null>(null);

  const validateForm = useCallback(() => {
    // reset form errors
    const errors: { [key: string]: string } = {};
    const warnings: { [key: string]: string } = { file: '' };

    if (!file) {
      errors.file = 'Please choose an image file.';
    }

    if (file && imgDimensions && Math.max(imgDimensions.width, imgDimensions.height) < 540) {
      errors.file = 'Image must be at least 540px in width or height (ideally, 1080px in both dimensions).';
    }

    // check if the image is a square
    if (file && imgDimensions && imgDimensions.width !== imgDimensions.height) {
      warnings.file = 'This image is not square so will be cropped. ';
    }

    if (file && imgDimensions && Math.min(imgDimensions.width, imgDimensions.height) < 1080) {
      warnings.file += `This image is smaller than expected at ${imgDimensions.width}x${imgDimensions.height} so will be upscaled to 1080x1080 and may look blurred.`;
    }

    setFormErrors(errors);
    setFormWarnings(warnings);

    return Object.keys(errors).length === 0;
  }, [file, imgDimensions]);

  const firstUpdate = useRef<boolean>(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    validateForm();
  }, [validateForm]);

  const handleFileSelect = useCallback(async (files: Array<File>) => {
    if (files.length < 1) {
      return;
    }

    const [newFile] = files;

    if (!['image/jpeg'].includes(newFile.type)) {
      return;
    }

    setFile(newFile);
    setImgDimensions(null);

    const url = await getBase64(newFile);
    setImageData(url);
  }, []);

  const handleImageLoad = useCallback((event: SyntheticEvent<HTMLImageElement>) => {
    const img = event.currentTarget;
    setImgDimensions({
      width: img.naturalWidth,
      height: img.naturalHeight,
    });
  }, []);

  const handleSave = useCallback(async () => {
    if (!validateForm() || !imageData) {
      return;
    }

    setIsSaving(true);

    try {
      const uploadFeaturedImage = httpsCallable<{ imageData: string, docId: string }>(getFunctions(), 'featuredImages-uploadFeaturedImage');

      await uploadFeaturedImage({
        imageData,
        docId,
      });
      onDismiss();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong while uploading featured image.');
      setIsSaving(false);
    }
  }, [docId, imageData, onDismiss, validateForm]);

  const savingDisabled = Object.keys(formErrors).length > 0;

  return (
    <Modal
      title="Upload Featured Image"
      open
      confirmLoading={isSaving}
      okButtonProps={{ disabled: savingDisabled }}
      cancelButtonProps={{ disabled: isSaving }}
      okText="Upload"
      onOk={handleSave}
      onCancel={onDismiss}
    >
      <Form.Item
        label="Featured Image"
        tooltip='Ideal size: 1080x1080px. Image will be automatically scaled up/down to this size, and cropped to a square.'
        validateStatus={formErrors.file ? 'error' : (formWarnings.file ? 'warning' : '')}
        help={formErrors.file || formWarnings.file || (!file ? 'Please select a JPEG image file' : '')}
      >
        <Dropzone onDrop={handleFileSelect} accept={{ 'image/jpeg': ['.jpg'] }} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: 'thumb-dropzone' })}>
              <input {...getInputProps()} />
              {file && imageData ? (
                <img alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} src={imageData} onLoad={handleImageLoad} />
              ) : (
                <div className="thumb-dropzone-button">
                  {isSaving ? (
                    <LoadingOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
                  ) : (
                    <PictureOutlined style={{}} />
                  )}
                  <div>Choose file</div>
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </Form.Item>
      <p>After clicking 'Upload', the image will be saved immediately without needing to 'Save' the doc. The old image will be deleted forever.</p>
    </Modal>
  );
}

export default ImageUploadDialog;
