import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getFirestore, collection, doc, getDoc, getDocs, deleteDoc, serverTimestamp, Timestamp, setDoc, query } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { LinkOutlined } from '@ant-design/icons';
import {
  Card,
  Button,
  Input,
  Select,
  Form,
  AutoComplete,
  Popconfirm,
  Spin,
  Row,
  Col,
  Divider,
  message,
  DatePicker,
  Checkbox,
  Modal, Space,
} from 'antd';
import { Helmet } from 'react-helmet';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import dayjs, { Dayjs, isDayjs } from 'dayjs';

import type {
  DocMetadataObj, DocObj, DocType,
} from '../../types';
import {
  getReadingTime, generateUniqueSlug,
} from '../../utils';
import {
  tabOptions, docTypeOptions, visibilityOptions,
} from '../../config';
import AuthedPage from '../AuthedPage';
import ErrorBoundary from '../ErrorBoundary';
import './DocEdit.scss';
import Editor from '../Editor';
import EditableTagGroup from '../EditableTagGroup';
import { AutoCompleteOption, FileOption } from './formTypes';
import MediaFormItem from './MediaFormItem';
import FolderFormItems from './FolderFormItems';
import SubtitleFormItem from './SubtitleFormItem';
import { TabValue } from '../../types';
import useMediaFileOptions from '../../hooks/useMediaFileOptions';
import useSettings from '../../hooks/useSettings';
import { MetadataType } from '../Metadata/Metadata';
import ImageUploadDialog from './ImageUploadDialog';

const { Item: FormItem } = Form;

type FormErrors = {
  slug?: string,
  title?: string,
  sourceURL?: string,
  secondaryFolders?: string,
};

const DocEdit = () => {
  const [item, setItem] = useState<DocObj | null>(null);
  const [itemMetadata, setItemMetadata] = useState<DocMetadataObj | null>();
  const [readingTime, setReadingTime] = useState<number>(0);
  const [authorOptions, setAuthorOptions] = useState<Array<AutoCompleteOption>>([]);
  const [slugs, setSlugs] = useState<Array<string>>([]);
  const [categories, setCategories] = useState<Array<MetadataType>>([]);
  const [topics, setTopics] = useState<Array<MetadataType>>([]);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [imageUploadDialogVisible, setImageUploadDialogVisible] = useState<boolean>(false);
  const [cacheBuster, setCacheBuster] = useState(Date.now());

  const refreshFeaturedImageThumb = useCallback(() => {
    setCacheBuster(Date.now()); // Set a new timestamp to force reload
  }, []);

  const { settings } = useSettings();
  const { loadFiles, fileOptions, isLoadingFiles, initializedLoadingAudio, initializedLoadingVideo } = useMediaFileOptions();

  const navigate = useNavigate();
  const params = useParams();
  const { tab, docId } = params as { tab: TabValue, docId: string };

  const currentVimeo: FileOption | undefined = useMemo(
    () => fileOptions.video.find((v) => v.downloadURL === item?.mediaURL),
    [fileOptions.video, item],
  );

  const loadMetadata = useCallback(async (overrideDocId?: string) => {
    const docIdToLoad = overrideDocId || docId;
    const querySnapshot = await getDocs(collection(doc(collection(getFirestore(), 'docs'), docIdToLoad), 'metadata'));
    let docMetadata: DocMetadataObj | undefined;
    querySnapshot.forEach((result) => {
      docMetadata = { id: result.id, ...result.data() };
      if (docMetadata.scheduledFor) {
        docMetadata.scheduledFor = (docMetadata
          .scheduledFor as unknown as Timestamp).toDate();
      }
    });
    if (!docMetadata) {
      docMetadata = {};
    }
    setItemMetadata(docMetadata);
  }, [docId]);

  const initialLoad = useCallback(async () => {
    const currentTime = serverTimestamp();

    const currentDocPromise = new Promise<DocObj>(async (resolve, reject) => {
      if (docId === 'new') {
        resolve({
          id: docId,
          title: 'New doc',
          slug: '',
          content: '',
          tab,
          order: 0,
          type: 'text',
          visibility: 'public',
          tags: '',
          duration: 0,
          createdAt: currentTime as Timestamp,
          updatedAt: currentTime as Timestamp,
        });
      } else {
        try {
          const docSnapshot = await getDoc(doc(collection(getFirestore(), 'docs'), docId));

          if (docSnapshot.metadata.fromCache) {
            throw new Error('No Internet!');
          }

          if (docSnapshot.exists()) {
            resolve({
              id: docSnapshot.id,
              ...docSnapshot.data() as Omit<DocObj, 'id'>,
            });
          } else {
            throw new Error('Doc not found.');
          }
        } catch (error) {
          reject(error);
        }
      }
    });

    const [currentDoc, authorsSnapshot, slugsSnapshot,
      categoriesSnapshot, topicsSnapshot,
    ] = await Promise.all([
      currentDocPromise,
      getDoc(doc(collection(getFirestore(), 'transients'), 'authors')),
      getDoc(doc(collection(getFirestore(), 'transients'), 'doc-slugs')),
      getDocs(query(collection(getFirestore(), 'categories'))),
      getDocs(query(collection(getFirestore(), 'topics'))),
    ]);

    setAuthorOptions((authorsSnapshot.data() as { list: string[] }).list.map((author) => ({ value: author, label: author })));
    setSlugs((slugsSnapshot.data() as { list: string[] }).list.filter((slug) => slug !== currentDoc.slug));

    const loadingCategories: Array<MetadataType> = [];
    categoriesSnapshot.forEach((cat) => {
      const categoryData = cat.data() as Omit<MetadataType, 'id'>;
      const categoryId = cat.id;
      loadingCategories.push({
        id: categoryId,
        ...categoryData,
      });
    });
    setCategories(loadingCategories);

    const loadingTopics: Array<MetadataType> = [];
    topicsSnapshot.forEach((topic) => {
      const topicData = topic.data() as Omit<MetadataType, 'id'>;
      const topicIdId = topic.id;
      loadingTopics.push({
        id: topicIdId,
        ...topicData,
      });
    });
    setTopics(loadingTopics);

    setReadingTime(getReadingTime(currentDoc.content));

    setItem(currentDoc);
    setIsLoading(false);

    if (currentDoc.type !== 'text') {
      loadFiles(currentDoc.type);
    }

    // Fetch metadata and subtitles for current doc
    if (docId === 'new') {
      setItemMetadata({});
    } else {
      loadMetadata();
    }
  }, [docId, tab, loadMetadata, loadFiles]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  const updateFoldersField = useCallback((folders: { [folderId: string]: { order?: number } }) => {
    if (item) {
      setItem({ ...item, folders });
    }
  }, [item]);

  const validateForm = useCallback((): boolean => {
    if (!item) {
      return false;
    }

    // reset form errors
    const newFormErrors: FormErrors = {};

    if (!item.title) {
      newFormErrors.title = 'Title is required';
    } else if (settings && item.title.length > settings.maxDocTitleLength) {
      newFormErrors.title = `Title cannot be longer than ${settings.maxDocTitleLength} characters`;
    }

    if (!item.slug) {
      newFormErrors.slug = 'Slug is required';
    } else if (slugs.includes(item.slug)) {
      newFormErrors.slug = 'Slug must be unique in docs';
    }

    if (item.folder && item.folders && item.folders[item.folder]) {
      newFormErrors.secondaryFolders = 'Primary folder should not be repeated as an additional folder. Additional folders are optional and only need to be set when the item should show up in multiple folders.';
    }

    if (itemMetadata) {
      if (!itemMetadata.sourceURL) {
        newFormErrors.sourceURL = 'Source is required';
      } else if (itemMetadata.sourceURL && currentVimeo?.vimeoID
        && itemMetadata.sourceURL.includes(currentVimeo.vimeoID)) {
        newFormErrors.sourceURL = 'Source URL must not be the same as the Vimeo URL. Please enter the original source URL (eg. on YouTube) or a description of where the video originates.';
      }
    }

    setFormErrors(newFormErrors);

    return Object.keys(newFormErrors).length === 0;
  }, [item, itemMetadata, settings, slugs, currentVimeo]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleChange = useCallback((key: string, value: string | boolean | string[]) => {
    if (!item) {
      return;
    }

    let val: string | boolean | string[] | null | undefined;

    switch (key) {
      case 'title':
      case 'content':
      case 'author':
      case 'folder':
      case 'mediaURL':
      case 'tags':
        val = typeof value === 'string' ? value : null;
        break;
      case 'slug':
        val = typeof value === 'string' ? generateUniqueSlug(value) : null;
        break;
      case 'tab':
        val = typeof value === 'string' && Object.keys(tabOptions).includes(value) ? value : null;
        break;
      case 'type':
        val = typeof value === 'string' && Object.keys(docTypeOptions).includes(value) ? value : null;
        if (val !== 'text') {
          if (val === 'audio' && !initializedLoadingAudio) {
            loadFiles(val as DocType);
          } else if (val === 'video' && !initializedLoadingVideo) {
            loadFiles(val as DocType);
          }
        }
        break;
      case 'visibility':
        val = typeof value === 'string' && Object.keys(visibilityOptions).includes(value) ? value : null;
        break;
      case 'hideFromLatestUpdates':
        val = typeof value === 'boolean' ? value : null;
        break;
      case 'categories':
      case 'topics':
        val = value;
        break;
      default:
        val = null;
    }

    if (val === null) {
      return;
    }

    if (key === 'content') {
      setTimeout(() => {
        const newReadingTime = getReadingTime(val as string);
        setReadingTime(newReadingTime);
      }, 0);
    }
    setItem({ ...item, [key]: val });
  }, [item, loadFiles, initializedLoadingAudio, initializedLoadingVideo]);

  useEffect(() => {
    if (item && item.type === 'text' && item.duration !== readingTime) {
      setItem({ ...item, duration: readingTime });
    }
  }, [item, readingTime]);

  const handleMediaURLChange = useCallback((option: FileOption, fileType: 'audio' | 'video') => {
    if (fileType === 'audio') {
      setItem((prevItem) => prevItem ? {
        ...prevItem,
        waveformData: option.waveformData,
        duration: option.duration,
        mediaURL: option.value,
      } : null);
    } else if (fileType === 'video') {
      setItem((prevItem) => prevItem ? {
        ...prevItem,
        duration: option.duration,
        mediaURL: option.value,
        hlsURL: option.hlsURL,
        downloadURL: undefined,
      } : null);
    }
  }, []);

  const handleMetadataChange = useCallback((key: string, value: string | Dayjs | null) => {
    if (!itemMetadata) {
      return;
    }

    let val;

    switch (key) {
      case 'sourceURL':
      case 'amaraID':
        val = typeof value === 'string' ? value : null;
        break;
      case 'scheduledFor':
        val = isDayjs(value) ? value.startOf('hour').toDate() : null;
        break;
      default:
        val = null;
    }

    setItemMetadata({ ...itemMetadata, [key]: val });
  }, [itemMetadata]);

  const save = useCallback(async () => {
    if (!item) {
      return;
    }

    setIsSaving(true);

    const {
      id, ...rest
    } = item;
    const docsCollection = collection(getFirestore(), 'docs');
    const ref = id === 'new' ? doc(docsCollection) : doc(docsCollection, id);
    const metadataCollection = collection(ref, 'metadata');
    const metadataRef = itemMetadata && itemMetadata.id
      ? doc(metadataCollection, itemMetadata.id)
      : doc(metadataCollection, 'metadata');

    const data = {
      ...rest,
      downloadURL: undefined,
      updatedAt: serverTimestamp(),
    };


    // Remove optional fields if they are empty as Firestore cannot accept 'undefined' values
    if (!item.waveformData) {
      delete data.waveformData;
    }
    if (!item.mediaURL) {
      delete data.mediaURL;
    }
    if (!item.hlsURL) {
      delete data.hlsURL;
    }
    // Delete downloadURLs (no longer used)
    delete data.downloadURL;

    const { ...metadata } = itemMetadata;
    if (metadata.id) {
      delete metadata.id;
    }
    if (data.visibility !== 'private') {
      delete metadata.scheduledFor;
    }

    try {
      await setDoc(ref, data);
      await setDoc(metadataRef, metadata);

      const updatedDoc: DocObj = { id: ref.id, ...data as Omit<DocObj, 'id'> };

      setItem(updatedDoc);

      message.success('Successfully saved doc');

      if (updatedDoc.type === 'video') {
        const subtitleImportByItemId = httpsCallable<{ id: string }>(getFunctions(), 'subtitleImport-byItemId');
        const amaraSyncByItemId = httpsCallable<{ id: string }>(getFunctions(), 'amaraSync-byItemId');
        Promise.all([
          subtitleImportByItemId({ id }),
          amaraSyncByItemId({ id }),
        ]).then(() => {
          setTimeout(() => {
            // This may be an old version of loadMetadata after a new doc has been saved, so we pass in the docId as an override
            loadMetadata(ref.id);
          }, 500);
        });
      }
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while saving the doc');
    } finally {
      setIsSaving(false);
      navigate(`/${tab}/docs/${ref.id}`);
    }
  }, [item, itemMetadata, tab, loadMetadata, navigate]);

  const videoIsNotSetUpCorrectly = useCallback((): string | null => {
    if (!currentVimeo) {
      return null;
    }

    if (!currentVimeo.language && currentVimeo.rating === 'unrated') {
      return "You haven't set the language or rating for this video";
    }

    if (!currentVimeo.language) {
      return "You haven't set the language for this video";
    }

    if (currentVimeo.rating === 'unrated') {
      return "You haven't set the rating for this video";
    }

    return null;
  }, [currentVimeo]);

  const handleSave = useCallback(async () => {
    if (!item) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    const reasonVideoIsNotSetUpCorrectly = videoIsNotSetUpCorrectly();
    if (reasonVideoIsNotSetUpCorrectly) {
      Modal.confirm({
        title: 'The Vimeo settings appear not have have been set correctly',
        content: `${reasonVideoIsNotSetUpCorrectly}. Are you ready to save? (if you already fixed this on the Vimeo website, you can ignore this message)`,
        okText: 'Save',
        cancelText: 'Cancel',
        onOk: save,
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      save();
    }
  }, [item, validateForm, videoIsNotSetUpCorrectly, save]);

  const handleDelete = useCallback(async () => {
    if (!item) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteDoc(doc(collection(getFirestore(), 'docs'), item.id));

      message.success('Successfully deleted the doc.');
      navigate(`/${item.tab}/`);
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while deleting the doc');
    }
  }, [item, navigate]);

  const handleSlugFocus = useCallback(() => {
    if (!item) {
      return;
    }

    if (!item.slug && item.title) {
      setItem({ ...item, slug: generateUniqueSlug(item.title, slugs) });
    }
  }, [item, slugs]);

  const isNew = useMemo(() => docId === 'new', [docId]);

  const savingDisabled = useMemo(() => isLoading
    || isDeleting
    || Object.keys(formErrors).length > 0, [isLoading, isDeleting, formErrors]);

  const toggleImageUploadDialog = useCallback(() => {
    if (isNew) {
      message.warning('Please save the new doc before uploading an image');
      return;
    }

    setImageUploadDialogVisible((visible) => !visible);

    refreshFeaturedImageThumb();
  }, [isNew, refreshFeaturedImageThumb]);

  const deleteConfirmTitle = item
    ? (
      <span>
          {'Are you sure you want to delete '}
        <strong>{item.title}</strong>
          ?
        </span>
    )
    : '';

  const saveButton = (
    <Button
      type="primary"
      size="large"
      onClick={handleSave}
      loading={isSaving}
      disabled={savingDisabled}
    >
      Save
    </Button>
  );

  return (
    <AuthedPage noBackground fullwidth requiredPermission="edit_content">
      {item && (
        <Helmet>
          <title>{item.title}</title>
        </Helmet>
      )}

      <Spin spinning={isLoading} delay={200}>
        <div className="DocActionButtons DocActionButtons--top">
          <div className="DocActionButtons__container">
            <h1>{item && item.title}</h1>
            {saveButton}
          </div>
        </div>

        <Divider />

        <div className="DocEdit__container">
          <Row
            gutter={{
              xs: 8, sm: 16, md: 24, lg: 32,
            }}
          >
            <Col md={{ span: 24 }} lg={{ span: 16 }} xl={{ span: 18 }}>
              <Card>
                <FormItem
                  label="Title"
                  validateStatus={formErrors.title ? 'error' : ''}
                  help={formErrors.title}
                >
                  <Input
                    value={item ? item.title : ''}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange('title', event.target.value)}
                    disabled={isSaving || isDeleting}
                  />
                </FormItem>
                <FormItem
                  label="Slug"
                  validateStatus={formErrors.slug ? 'error' : ''}
                  help={formErrors.slug}
                >
                  <Input
                    value={item ? item.slug : ''}
                    onChange={(event) => handleChange('slug', event.target.value)}
                    onFocus={handleSlugFocus}
                    disabled={isSaving || isDeleting}
                  />
                </FormItem>
                <FormItem label="Author">
                  <AutoComplete
                    value={item && item.author ? item.author : ''}
                    style={{ width: '100%' }}
                    placeholder="Type or select an author"
                    options={authorOptions}
                    onChange={(value) => handleChange('author', value)}
                    filterOption
                    disabled={isSaving || isDeleting}
                  />
                </FormItem>
                <FormItem label="Featured Image">
                  <Space>
                    {item?.id && (
                      <object data={`https://pvappitemimages.b-cdn.net/${item?.id}/thumb@3x.jpg?cb=${cacheBuster}`} type="image/jpeg" style={{ width: '90px', height: '90px' }}>
                        {/* The inner img is a default, if no featured image exists at the above URL */}
                        <img src={`/item-${item?.type || 'text'}-default.png`} alt='' style={{ width: '90px', height: '90px' }} />
                      </object>
                    )}
                    <Button
                      size="small"
                      onClick={toggleImageUploadDialog}
                      disabled={isNew || isSaving || isDeleting}
                    >
                      Replace image...
                    </Button>
                  </Space>
                </FormItem>
                <FormItem label="Categories">
                  <Select
                    mode="multiple"
                    placeholder="Select one or more Categories"
                    value={item?.categories}
                    onChange={(values, options) => {
                      const opts = Array.isArray(options) ? options : [options];
                      handleChange('categories', opts.map((val)=> val.value));
                    }}
                    style={{ width: '100%' }}
                    options={categories.map((category) => ({
                      value: category.id,
                      label: category.en,
                    }))}
                  />
                </FormItem>
                <FormItem label="Topics">
                  <Select
                    mode="multiple"
                    placeholder="Select one or more Topics"
                    value={item?.topics}
                    onChange={(values, options) => {
                      const opts = Array.isArray(options) ? options : [options];
                      handleChange('topics', opts.map((val)=> val.value));
                    }}
                    style={{ width: '100%' }}
                    options={topics.map((topic) => ({
                      value: topic.id,
                      label: topic.en,
                    }))}
                  />
                </FormItem>
                <FormItem label="Content">
                  {!isLoading && (
                    <ErrorBoundary>
                      <Editor
                        value={item && item.content ? item.content : ''}
                        onChange={(value: string) => handleChange('content', value)}
                        readOnly={isSaving || isDeleting}
                      />
                    </ErrorBoundary>
                  )}
                  <p className="DocEdit__read-time">
                    {`${Math.ceil(readingTime / 60)} min read`}
                  </p>
                </FormItem>
              </Card>
              <Card>
                <FormItem
                  label="Source"
                  tooltip="This tells future editors where this material came from. For videos, where available you should paste an existing YouTube video with associated subtitles. If no URL is available please enter a short description of the source."
                  validateStatus={formErrors.sourceURL ? 'error' : ''}
                  help={formErrors.sourceURL}
                >
                  <Input
                    value={itemMetadata ? itemMetadata.sourceURL : ''}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleMetadataChange('sourceURL', event.target.value)}
                    disabled={isSaving || isDeleting}
                    required
                    placeholder="https://www.youtu.be/s7xfWQo_cPo, 'Original recording', 'Snippet from the book Miracle of Mindfulness' etc "
                  />
                </FormItem>
                <SubtitleFormItem item={item} docMetadata={itemMetadata} />
                <FormItem label="Amara ID (optional)" tooltip="Amara may be used to source subtitles. This ID will automatically be set, where possible, to the Amara ID matching the YouTube URL (if provided in the 'Source' field). You can set also set it manually.">
                  <Input
                    value={itemMetadata ? itemMetadata.amaraID : ''}
                    onChange={(event) => handleMetadataChange('amaraID', event.target.value)}
                    disabled={isSaving || isDeleting}
                    placeholder="0jeFaZxnfwFh"
                    style={{ maxWidth: 250 }}
                  />
                  {' '}
                  {itemMetadata?.amaraID && (
                    <a href={`https://amara.org/en/videos/${itemMetadata.amaraID}/`} target="_blank" rel="noopener noreferrer">
                      <LinkOutlined />
                    </a>
                  )}
                </FormItem>
              </Card>
            </Col>

            <Col md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
              <Card>
                <FolderFormItems
                  doc={item}
                  handleChange={handleChange}
                  isDisabled={isSaving || isDeleting}
                  tab={tab}
                  updateFolders={updateFoldersField}
                  formErrors={formErrors}
                />
                <FormItem label="Type">
                  <Select
                    value={item && item.type ? item.type : ''}
                    style={{ width: '100%' }}
                    onChange={(value) => handleChange('type', value)}
                    disabled={isSaving || isDeleting}
                  >
                    {Object.entries(docTypeOptions).map(([key, value]) => (
                      <Select.Option key={key} value={key}>{value}</Select.Option>
                    ))}
                  </Select>
                </FormItem>
                <Spin spinning={isLoadingFiles}>
                  <MediaFormItem
                    doc={item}
                    fileOptions={fileOptions}
                    handleMediaURLChange={handleMediaURLChange}
                    isDisabled={isSaving || isDeleting}
                  />
                </Spin>
                <FormItem label="Visibility">
                  <Select
                    value={item && item.visibility ? item.visibility : ''}
                    style={{ width: '100%' }}
                    onChange={(value) => handleChange('visibility', value)}
                    disabled={isSaving || isDeleting}
                  >
                    {Object.entries(visibilityOptions).map(([key, value]) => (
                      <Select.Option key={key} value={key}>{value}</Select.Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  label="Schedule this item"
                  tooltip="When visibility is set to private, you may schedule the item to later go public."
                >
                  <DatePicker
                    format="YYYY/MM/DD HH:00"
                    showTime={{ format: 'HH' }}
                    onChange={(value) => handleMetadataChange('scheduledFor', value)}
                    value={(itemMetadata?.scheduledFor) ? dayjs(itemMetadata.scheduledFor) : null}
                    disabled={!(item && item.visibility && item.visibility === 'private')}
                  />
                </FormItem>
                <FormItem label="Hide from latest updates">
                  <Checkbox
                    checked={Boolean(item?.hideFromLatestUpdates)}
                    onChange={(e) => handleChange('hideFromLatestUpdates', e.target.checked)}
                  />
                </FormItem>
                <FormItem label="Tags">
                  <EditableTagGroup
                    tags={item && item.tags ? item.tags.split(',') : []}
                    onChange={(tags) => handleChange('tags', tags.join(','))}
                  />
                </FormItem>
                <p className="DocEdit__web-app-link">
                  {item && (
                    <a href={`https://web.plumvillage.app/item/${item.slug}`} target="_blank" rel="noopener">
                      <LinkOutlined />
                      {' View in web app'}
                    </a>
                  )}
                </p>
              </Card>
            </Col>
          </Row>
        </div>
        {/* END: .DocEdit__container */}

        <Divider />

        <div className="DocActionButtons DocActionButtons--bottom">
          <div className="DocActionButtons__container">
            {!isNew && (
              <Popconfirm
                title={deleteConfirmTitle}
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  danger
                  ghost
                  size="large"
                  loading={isDeleting}
                  disabled={isLoading || isSaving}
                >
                  Delete doc
                </Button>
              </Popconfirm>
            )}
            {saveButton}
          </div>
        </div>
      </Spin>

      {imageUploadDialogVisible && (
        <ImageUploadDialog onDismiss={toggleImageUploadDialog} docId={docId} />
      )}
    </AuthedPage>
  );
};


export default DocEdit;
