import { Tag } from 'antd';
import React from 'react';
import { SubtitleInfo } from '../types';
import { languageCodeAndName } from '../utils';

function SubtitleLangTags({ subtitles } : { subtitles: Array<SubtitleInfo> }) {
  return <>
    {subtitles.length ? subtitles.map((s) => (
      <Tag title={`Updated: ${s.updatedAt.toDate().toLocaleString()}, Created ${(s.createdAt || s.updatedAt).toDate().toLocaleString()}`} key={s.language}>
        {languageCodeAndName(s.language)}
      </Tag>
    )) : '-'}
  </>;
}

export default SubtitleLangTags;
