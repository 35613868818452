import AuthedPage from './AuthedPage';
import { Helmet } from 'react-helmet';
import { Alert, Button, InputRef, Table, Tag } from 'antd';
import { vimeoGuidelinesUrl } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { type DocMetadataObj, DocObj, VideoRecord, VimeoVideoResult } from '../types';
import { LinkOutlined } from '@ant-design/icons';
import { getVimeoId, languageCodeAndName } from '../utils';
import { DateTime } from 'luxon';
import useSearchFilter from '../hooks/useSearchFilter';
import { collection, collectionGroup, getDocs, getFirestore, query, where } from 'firebase/firestore';

type VideoObj = VimeoVideoResult & {
  createdAt: DateTime;
  record?: VideoRecord;
};

type VideoObjWithSource = VideoObj & {
  sourceURL: string;
};

const { Column } = Table;

export const getVideoRecords = async (): Promise<Array<VideoRecord>> => {
  const videosSnap = await getDocs(collection(getFirestore(), 'videos'));

  const videos: Array<VideoRecord> = [];
  videosSnap.forEach((videoSnap) => {
    videos.push({ id: videoSnap.id, ...videoSnap.data() as Omit<VideoRecord, 'id'> });
  });
  return videos;
};

type DocWithMetadata = DocObj & {
  sourceURL: string,
  amaraID: string,
};

const Video = () => {
  const [videos, setVideos] = useState<Array<VideoObj>>([]);
  const [videoDocs, setVideoDocs] = useState<Array<DocWithMetadata>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const searchInput = useRef<InputRef>(null);

  const { filterDropdown, searchFilterIcon, onFilterDropdownOpenChange, renderHighlight } = useSearchFilter();

  const loadVideoDocs = useCallback(async () => {
    const videosPromise = getDocs(query(collection(getFirestore(), 'docs'), where('type', '==', 'video')));
    const metadataPromise = getDocs(collectionGroup(getFirestore(), 'metadata'));

    const allMetadata = (await metadataPromise).docs.map((metaSnap) => {
      const metadata: DocMetadataObj = metaSnap.data();
      return { ...metadata, itemId: metaSnap.ref.parent.parent?.id };
    });
    setVideoDocs((await videosPromise).docs.map((itemSnapshot) => {
      const itemData: DocObj = itemSnapshot.data() as DocObj;

      const itemMetadata = allMetadata.find((m) => m.itemId === itemSnapshot.id);

      return {
        ...itemData,
        id: itemSnapshot.id,
        sourceURL: itemMetadata?.sourceURL || '',
        amaraID: itemMetadata?.amaraID || '',
      };
    }));
  }, []);

  useEffect(() => {
    loadVideoDocs();
  }, [loadVideoDocs]);

  const videoSourceMap = useMemo(() => {
    const map = new Map<string, string>();
    for (const doc of videoDocs) {
      const vimeoId = getVimeoId(doc.hlsURL || '');
      if (vimeoId) {
        map.set(vimeoId, doc.sourceURL || '');
      }
    }
    return map;
  }, [videoDocs]);

  const videosWithSource = useMemo(() => {
    return videos.map((video) => {
      const sourceURL = videoSourceMap.get(video.vimeoID);
      return { ...video, sourceURL };
    });
  }, [videos, videoSourceMap]);

  useEffect(() => {
    getVideoRecords().then((videoRecords) => {
      const getVimeoVideos = httpsCallable<unknown, Array<VimeoVideoResult>>(getFunctions(), 'getVimeoVideos');

      getVimeoVideos().then((result) => {
        setVideos(result.data.map((v) => {
          const record = videoRecords.find((r) => r.vimeoId === v.vimeoID);
          return {
            ...v,
            createdAt: DateTime.fromISO(v.created),
            record,
          };
        }));
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <AuthedPage requiredPermission="edit_content">
      <Helmet>
        <title>Videos</title>
      </Helmet>

      <Alert
        message={
          'Upload videos to Vimeo, then they will show in the list below and be available to select when adding a Doc'
        }
        action={(
          <Button type="link" href={vimeoGuidelinesUrl} target="_blank">
            Full Instructions
            <>&nbsp;</>
            <FontAwesomeIcon icon="external-link-alt" />
          </Button>
        )}
        type="info"
        showIcon
        style={{ marginBottom: 16 }}
      />

      <Table
        loading={isLoading}
        dataSource={videosWithSource}
        rowKey={(video: VideoObj) => video.downloadURL || ''}
        pagination={{ defaultPageSize: 200 }}
      >
        <Column
          title="Title"
          dataIndex="title"
          filterDropdown={filterDropdown('name', searchInput)}
          filterIcon={searchFilterIcon}
          onFilter={(value, file: VideoObj) => file
            .title.toLowerCase().includes((value as string).toLowerCase())}
          onFilterDropdownOpenChange={onFilterDropdownOpenChange(searchInput)}
          render={renderHighlight}
          sorter={(a: VideoObj, b: VideoObj) => a.title.localeCompare(b.title)}
        />
        <Column
          title="Database ID"
          dataIndex={['record', 'id']}
          render={(id: string) => <small>{id}</small> || '-'}
        />
        <Column
          title="Vimeo"
          dataIndex="hlsURL"
          render={(hlsURL: string, row: DocObj) => {
            if (!row.hlsURL) {
              return (
                <Tag
                  title="HTTP Live Streaming support must be available for the video to support subtitles in the app. This may have happened because the item was set up just after Vimeo upload, and the HLS URL was not ready. Try re-saving."
                  color="#f00"
                >
                  Missing HLS support
                </Tag>
              );
            }

            const vimeoID = getVimeoId(hlsURL);
            return vimeoID ? (
              <>
                <a href={`https://vimeo.com/manage/videos/${vimeoID}/`} target="_blank" rel="noopener noreferrer">
                  Manage
                  {' '}
                  <FontAwesomeIcon icon="external-link-alt" />
                </a>
                <br />
                <a href={`https://vimeo.com/${vimeoID}/`} target="_blank" rel="noopener noreferrer">
                  Public
                  {' '}
                  <LinkOutlined />
                </a>
              </>
            ) : '-';
          }}
        />
        <Column
          title="Language"
          dataIndex="language"
          render={(langCode: string) => langCode ? languageCodeAndName(langCode) : '-'}
        />
        <Column
          title="Bunny Stream"
          dataIndex="hlsURL"
          render={(_, video: VideoObj) => {
            if (video.record) {
              return video.record.bunnyId ? (
                <a
                  href={`https://dash.bunny.net/stream/127868/library/videos?orderBy=date&search=${video.record.bunnyId}&videoId=${video.record.bunnyId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              ) : (
                'Currently uploading...'
              );
            }
          }}
        />
        <Column
          title="Source"
          dataIndex="sourceURL"
          filterDropdown={filterDropdown('sourceURL', searchInput)}
          filterIcon={searchFilterIcon}
          onFilter={(value, item: VideoObjWithSource) => item.sourceURL?.toLowerCase().includes((value as string).toLowerCase())}
          onFilterDropdownOpenChange={onFilterDropdownOpenChange(searchInput)}
          filterMultiple={false}
          render={(link: string) => (link && link.startsWith('http') && !link.includes(' ') ? (
            <a href={link} target="_blank" rel="noopener noreferrer">
              {/* eslint-disable-next-line no-nested-ternary */}
              {link.includes('youtu') ? 'YouTube' : (link.includes('vimeo') ? 'Vimeo' : (
                <small>{link}</small>
              ))}
              {' '}
              <LinkOutlined />
            </a>
          ) : <small>{link || '-'}</small>)}
        />
        <Column
          title="Uploaded"
          dataIndex="created"
          render={
            (created: string) => DateTime.fromISO(created).toRelative()
          }
          sorter={(a: VideoObj, b: VideoObj) => a.createdAt.toMillis() - b.createdAt.toMillis()}
          defaultSortOrder="descend"
        />
      </Table>

    </AuthedPage>
  );
};

export default Video;
