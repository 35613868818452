import React, {
  useState, useEffect, useMemo, useCallback, useRef,
} from 'react';
import {
  Alert, Table, Tag, Tabs, Spin, Select, InputRef,
} from 'antd';
import { Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { DateTime } from 'luxon';
import { collection, collectionGroup, getDocs, getFirestore, query, Timestamp, where } from 'firebase/firestore';

import AuthedPage from './AuthedPage';
import type {
  DocMetadataObj, DocObj, DocSubtitleObj, SubtitleInfo, Visibility,
} from '../types';
import { getVimeoId, languageCodeAndName, languageName } from '../utils';
import VisibilityTag from './VisibilityTag';
import SubtitleLangTags from './SubtitleLangTags';
import { Line } from '@ant-design/charts';
import useFolders from '../hooks/useFolders';
import { useFolderColumnFilterItems } from '../hooks/useFolderColumnFilterItems';
import useSearchFilter from '../hooks/useSearchFilter';

const { Column } = Table;

type Row = DocObj & {
  sourceURL: string,
  amaraID: string,
  folderName: string,
  subtitles: Array<SubtitleInfo>,
};

type LangRow = {
  language: string,
  quantity: number,
};

function Subtitles() {
  const [loading, setLoading] = useState<boolean>(true);
  const [videos, setVideos] = useState<Array<Row>>([]);
  const [subtitles, setSubtitles] = useState<Array<SubtitleInfo>>([]);
  const [languageFilter, setLanguageFilter] = useState<string>();

  const { folders, isLoadingFolders } = useFolders();


  const loadVideos = useCallback(async () => {
    if (isLoadingFolders) {
      return;
    }

    const videosPromise = getDocs(query(collection(getFirestore(), 'docs'), where('type', '==', 'video')));
    const subtitlesPromise = getDocs(collectionGroup(getFirestore(), 'subtitles'));
    const metadataPromise = getDocs(collectionGroup(getFirestore(), 'metadata'));
    const allSubtitles = (await subtitlesPromise).docs.map((subtitleSnapshot) => {
      const subtitleData: DocSubtitleObj = subtitleSnapshot.data() as DocSubtitleObj;
      return {
        language: subtitleData.language,
        updatedAt: subtitleData.updatedAt,
        createdAt: subtitleData.createdAt,
        itemId: subtitleSnapshot.ref.parent.parent?.id,
      };
    });
    const allMetadata = (await metadataPromise).docs.map((metaSnap) => {
      const metadata: DocMetadataObj = metaSnap.data();
      return { ...metadata, itemId: metaSnap.ref.parent.parent?.id };
    });
    const rows: Array<Row> = (await videosPromise).docs.map((itemSnapshot) => {
      const itemData: DocObj = itemSnapshot.data() as DocObj;

      const itemMetadata = allMetadata.find((m) => m.itemId === itemSnapshot.id);
      const itemSubtitles = allSubtitles.filter((s) => s.itemId === itemSnapshot.id);

      return {
        ...itemData,
        id: itemSnapshot.id,
        folderName: folders.find((f) => f.id === itemData.folder)?.title || '',
        subtitles: itemSubtitles,
        sourceURL: itemMetadata?.sourceURL || '',
        amaraID: itemMetadata?.amaraID || '',
      };
    });

    setVideos(rows);
    setSubtitles(allSubtitles);
    setLoading(false);
  }, [isLoadingFolders, folders]);

  useEffect(() => {
    loadVideos();
  }, [loadVideos]);

  const subtitlesByLang = useMemo(() => {
    const map: { [lang: string]: number } = {};
    subtitles.forEach((s) => {
      map[s.language] = map[s.language] || 0;
      map[s.language] += 1;
    });
    return Object.entries(map).map(([language, quantity]) => ({ language, quantity }));
  }, [subtitles]);

  const langFilterValues = useMemo(() => {
    return subtitlesByLang.map(({ language: langCode }) => ({ text: languageName(langCode), value: langCode })).sort((a, b) => a.text?.localeCompare(b.text || '') || 0);
  }, [subtitlesByLang]);

  const lineChartData = useMemo(() => {
    // First fill the array with months (starting from November 2024)
    const firstMonth = DateTime.fromObject({ year: 2024, month: 11 });
    let currentMonth = firstMonth;
    const map: { [lang: string]: number } = {};
    do {
      map[currentMonth.toFormat('yyyy-MM')] = 0;
      currentMonth = currentMonth.plus({ months: 1 });
    } while (currentMonth < DateTime.now());

    subtitles.filter((s): s is SubtitleInfo & { createdAt: Timestamp } => !!s.createdAt).forEach((s) => {
      if (!languageFilter || s.language === languageFilter) {
        const key = DateTime.fromSeconds(s.createdAt.seconds).toFormat('yyyy-MM');
        map[key] = map[key] || 0;
        map[key] += 1;
      }
    });
    return Object.entries(map).map(([date, count]) => ({ date, count }))
      .sort((a, b) => a.date.localeCompare(b.date));
  }, [subtitles, languageFilter]);

  const folderOptions = useFolderColumnFilterItems(folders, true);

  const searchInput = useRef<InputRef>(null);
  const { filterDropdown, searchText, searchFilterIcon, onFilterDropdownOpenChange } = useSearchFilter();

  const tabItems = [
    {
      label: 'By Video',
      key: 'by-video',
      children: (
        <>
          <br />
          <Table
            loading={loading}
            dataSource={videos}
            size="small"
            pagination={{ defaultPageSize: 200 }}
            rowKey={(video: Row) => video.id}
            locale={{ emptyText: searchText ? 'Nothing found matching your search' : 'Sorry, you do not have any videos (yet).' }}
            scroll={{ x: 0 }}
          >
            <Column
              title="Folder"
              dataIndex="folderName"
              filters={folderOptions}
              onFilter={(value, item: Row) => item.folder === value || typeof item.folders?.[value as string] === 'object'}
              filterSearch={true}
              render={(text: string) => text || '-'}
              sorter={(a: Row, b: Row) => a.folderName.localeCompare(b.folderName)}
            />
            <Column
              title="Title"
              dataIndex="title"
              render={(title: string, row) => (
                <Link to={`/${row.tab}/docs/${row.id}`}>
                  {title}
                </Link>
              )}
              sorter={(a: Row, b: Row) => a.title.localeCompare(b.title)}
            />
            <Column
              title="Visibility"
              dataIndex="visibility"
              filters={[{
                text: 'Public',
                value: 'public',
              }, {
                text: 'Private',
                value: 'private',
              }]}
              onFilter={(value, doc: DocObj) => doc.visibility === value}
              render={(visibility: Visibility, doc: DocObj) => (
                <>
                  <VisibilityTag visibility={visibility} />
                  <br />
                  <a href={`https://web.plumvillage.app/item/${doc.slug}`} target="_blank" rel="noopener">
                    {'Web '}
                    <LinkOutlined />
                  </a>
                </>
              )}
            />
            <Column
              title="Vimeo"
              dataIndex="hlsURL"
              render={(hlsURL: string, row: DocObj) => {
                if (!row.hlsURL) {
                  return (
                    <Tag
                      title="HTTP Live Streaming support must be available for the video to support subtitles in the app. This may have happened because the item was set up just after Vimeo upload, and the HLS URL was not ready. Try re-saving."
                      color="#f00"
                    >
                      Missing HLS support
                    </Tag>
                  );
                }

                const vimeoID = getVimeoId(hlsURL);
                return vimeoID ? (
                  <>
                    <a href={`https://vimeo.com/manage/videos/${vimeoID}/`} target="_blank" rel="noopener noreferrer">
                      Manage
                      {' '}
                      <FontAwesomeIcon icon="external-link-alt" />
                    </a>
                    <br />
                    <a href={`https://vimeo.com/${vimeoID}/`} target="_blank" rel="noopener noreferrer">
                      Public
                      {' '}
                      <LinkOutlined />
                    </a>
                  </>
                ) : '-';
              }}
            />
            <Column
              title="Subtitles"
              dataIndex="subtitles"
              render={(videoSubs: Array<SubtitleInfo>) => (
                <SubtitleLangTags subtitles={videoSubs} />
              )}
              sorter={(a: Row, b: Row) => Math.max(
                ...a.subtitles.map((s) => s.updatedAt.seconds),
              ) - Math.max(
                ...b.subtitles.map((s) => s.updatedAt.seconds),
              )}
              filters={langFilterValues}
              onFilter={(value, item: Row) => item.subtitles.some((s) => s.language === value)}
            />
            <Column
              title="Source"
              dataIndex="sourceURL"
              filterDropdown={filterDropdown('sourceURL', searchInput)}
              filterIcon={searchFilterIcon}
              onFilter={(value, item: Row) => item.sourceURL.toLowerCase().includes((value as string).toLowerCase())}
              onFilterDropdownOpenChange={onFilterDropdownOpenChange(searchInput)}
              filterMultiple={false}
              render={(link: string) => (link && link.startsWith('http') && !link.includes(' ') ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {link.includes('youtu') ? 'YouTube' : (link.includes('vimeo') ? 'Vimeo' : (
                    <small>{link}</small>
                  ))}
                  {' '}
                  <LinkOutlined />
                </a>
              ) : <small>{link || '-'}</small>)}
            />
            <Column
              title="Amara"
              dataIndex="amaraID"
              render={(amaraID: string) => (amaraID ? (
                <a href={`https://amara.org/en/videos/${amaraID}/`} target="_blank" rel="noopener noreferrer">
                  <LinkOutlined />
                </a>
              ) : '-')}
            />
            <Column
              title="Created"
              dataIndex="createdAt"
              render={
                (createdAt: Timestamp) => DateTime.fromSeconds(createdAt.seconds).toRelative()
              }
              sorter={(a: Row, b: Row) => a.createdAt.seconds - b.createdAt.seconds}
              defaultSortOrder="descend"
            />
          </Table>
        </>
      ),
    },
    {
      label: 'By Language',
      key: 'by-language',
      children: (
        <Table
          loading={loading}
          dataSource={subtitlesByLang}
          size="small"
          pagination={{ defaultPageSize: 100 }}
          rowKey={(row: LangRow) => row.language}
          locale={{ emptyText: 'Sorry, you do not have any videos (yet).' }}
        >
          <Column
            title="Language"
            dataIndex="language"
            render={(language) => <>{languageCodeAndName(language)}</>}
            sorter={(a: LangRow, b: LangRow) => a.language.localeCompare(b.language)}
          />
          <Column
            title="Quantity"
            dataIndex="quantity"
            sorter={(a: LangRow, b: LangRow) => a.quantity - b.quantity}
            defaultSortOrder="descend"
          />
          <Column
            title="Coverage"
            dataIndex="quantity"
            render={(quantity) => (
              <>
                {videos.length ? Math.floor((quantity * 100) / videos.length) : '?'}
                %
              </>
            )}
          />
        </Table>
      ),
    },
    {
      label: 'By Month',
      key: 'by-month',
      children: (
        <>
          <p>Number of subtitle tracks added to the app each month. <small>(Should have rough correspondence to the number of human contributions but is also influenced by changes to our automation. Due to a breakage in automation, figures prior to November 2024 have been removed. November 2024 figures may be higher because the subtitles were first recorded as )</small></p>
          <Spin spinning={loading}>
            <div>
              <label>Filter by language:
                {' '}
                <Select defaultValue='' onChange={setLanguageFilter} style={{ width: 200 }} options={[{ value: '' }, ...subtitlesByLang.map(({ language }) => ({ value: language, label: languageCodeAndName(language) }))]} />
              </label>
            </div>
            <Line
              data={lineChartData}
              xField="date"
              yField="count"
              padding="auto"
            />
          </Spin>
        </>
      ),
    },
  ];

  return (
    <AuthedPage requiredPermission="edit_content">
      <Helmet>
        <title>Subtitles</title>
      </Helmet>

      {!loading && (
        <Alert
          message={`Across all ${videos.length} videos, there are ${subtitles.length} subtitle tracks.`}
          type="info"
          showIcon
        />
      )}
      <br />
      <Tabs defaultActiveKey="by-video" items={tabItems} />
    </AuthedPage>
  );
}

export default Subtitles;
